<template>
  <validation-observer ref="simpleRules">
    <b-overlay :show="show" rounded="sm">
      <b-form>
        <b-card v-if="ideaProfile">
          <b-card-title
            ><h2>{{ $t("IdeaForm.Edit_idea") }}</h2>
          </b-card-title>
          <hr />
          <!-- <br /> -->
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="Edit3Icon" />
                <span>{{ $t("dashboard.content") }}</span>
              </template>
              <br />
              <b-row>
                <!--  name-->
                <b-col md="6">
                  <b-form-group>
                    <label class="required"
                      >{{ $t("IdeaForm.Idea_name") }}
                    </label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="Idea name"
                    >
                      <b-form-input
                        v-model="ideaProfile.name"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.Idea_name')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Idea belongs to"
                    rules="required"
                  >
                    <b-form-group
                      label=""
                      label-for=""
                      :state="errors.length > 0 ? false : null"
                    >
                      <label class="required" for="owner_type"
                        >{{ $t("IdeaForm.Idea_belongs_to") }}
                      </label>
                      <v-select
                        id="State"
                        v-model="ideaProfile.typeofOwner"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createIdeaProfile.OwnerTypeOptionArabic
                            : createIdeaProfile.OwnerTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Country">{{
                        $t("IdeaForm.country_reside")
                      }}</label>
                      <v-select
                        id="Country"
                        v-model="ideaProfile.address.profileCountry"
                        :options="countries"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="
                          retreiveStates(
                            ideaProfile.address.profileCountry.value
                          )
                        "
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="State"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="State">{{
                        $t("IdeaForm.state_reside")
                      }}</label>

                      <v-select
                        id="State"
                        v-model="ideaProfile.address.profileState"
                        :options="statesOptions"
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="City"
                      rules="required"
                    >
                      <label class="required" for="City">{{
                        $t("IdeaForm.city_reside")
                      }}</label>

                      <b-form-input
                        id="City"
                        v-model="ideaProfile.address.profileCity"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.city_reside')"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Industry"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Industry"
                        >{{ $t("IdeaForm.industries_belong") }}
                      </label>
                      <div id="app">
                        <treeselect
                          v-model="ideaProfile.profileIndustries"
                          :multiple="true"
                          :placeholder="$t('common.Select')"
                          :options="industries"
                          @input="handeTreeSelect"
                          :normalizer="
                            $store.state.locale.locale == 'ar'
                              ? normalizerArabic
                              : normalizer
                          "
                        />
                      </div>
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="highlights"
                      :rules="`required|max:${$maxHighlightsProfile}`"
                    >
                      <label class="required" for="highlights">{{
                        $t("IdeaForm.Brief_overview")
                      }}</label>

                      <b-form-textarea
                        id="highlights"
                        v-model="ideaProfile.highlights"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.Brief_overview')"
                        rows="2"
                        max-rows="2"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Reference"
                      rules="required"
                    >
                      <label class="required" for="Reference"
                        >{{ $t("IdeaForm.Include_references") }}
                      </label>

                      <b-form-textarea
                        id="Reference"
                        v-model="ideaProfile.idea.ideaReference"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.Include_references_p')"
                        rows="2"
                        max-rows="2"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Owner Details"
                      rules="required"
                    >
                      <label class="required" for="Owner-Details"
                        >{{ $t("IdeaForm.description_owner") }}
                      </label>

                      <b-form-textarea
                        id="Owner-Details"
                        v-model="ideaProfile.detailsofOwner"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.description_owner')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <label class="required" for="description"
                        >{{ $t("IdeaForm.description_idea") }}
                      </label>
                      <b-form-textarea
                        id="description"
                        v-model="ideaProfile.description"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('IdeaForm.description_idea')"
                        rows="3"
                        max-rows="3"
                        no-auto-shrink
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="Category"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Category">{{
                        $t("IdeaForm.needs_from_sharing")
                      }}</label>
                      <v-select
                        id="Category"
                        v-model="ideaProfile.category"
                        :options="categories"
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                        @input="resetDataSaleType()"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  v-if="
                    ideaProfile.category.value == '62a584a04c2a541d1939a10c'
                  "
                >
                  <validation-provider
                    #default="{ errors }"
                    name="sale type"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="saleType">{{
                        $t("IdeaForm.How_sell_idea")
                      }}</label>
                      <v-select
                        id="saleType"
                        v-model="createIdeaProfile.selectedSaleTypeOption"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createIdeaProfile.SaleTypeOptionArabic
                            : createIdeaProfile.SaleTypeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  md="6"
                  v-if="
                    createIdeaProfile.selectedSaleTypeOption != undefined &&
                    createIdeaProfile.selectedSaleTypeOption.value == '1' &&
                    ideaProfile.category.value == '62a584a04c2a541d1939a10c'
                  "
                >
                  <b-row>
                    <b-col md="4">
                      <validation-provider
                        #default="{ errors }"
                        name="Currency"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label class="required" for="Currency">{{
                            $t("IdeaForm.Currency")
                          }}</label>
                          <v-select
                            id="Currency"
                            v-model="ideaProfile.currencyId"
                            :options="currencies"
                            :selectable="
                              (option) => !option.value.includes('select_value')
                            "
                            label="text"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="8">
                      <validation-provider
                        #default="{ errors }"
                        name="Price"
                        rules="required|integer"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <label class="required" for="Price">{{
                            $t("IdeaForm.Price")
                          }}</label>

                          <b-form-input
                            id="Price"
                            v-model="ideaProfile.priceNumber"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('IdeaForm.My_Own_Price')"
                          />
                          <b-form-invalid-feedback
                            :state="errors.length > 0 ? false : null"
                          >
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="status"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="Status">{{
                        $t("IdeaForm.Status")
                      }}</label>
                      <v-select
                        id="Status"
                        v-model="createIdeaProfile.status"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createIdeaProfile.StatusOptionArabic
                            : createIdeaProfile.StatusOption
                        "
                        :selectable="
                          (option) => !option.value.includes('select_value')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <validation-provider
                    #default="{ errors }"
                    name="blockchain exchange"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false : null">
                      <label class="required" for="blockchain_exchange">{{
                        $t("IdeaForm.blockchain_exchange")
                      }}</label>
                      <v-select
                        id="blockchain_exchange"
                        v-model="createIdeaProfile.selectedBlockChainObtion"
                        :options="
                          $store.state.locale.locale == 'ar'
                            ? createIdeaProfile.ExchangeOptionArabic
                            : createIdeaProfile.ExchangeOption
                        "
                        :selectable="
                          (option) => !option.value.includes('nothing_selected')
                        "
                        label="text"
                      />
                      <b-form-invalid-feedback
                        :state="errors.length > 0 ? false : null"
                      >
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('IdeaForm.Facebook')"
                    label-for="facebook"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Facebook"
                      rules="url"
                    >
                      <b-form-input
                        id="facebook"
                        v-model="ideaProfile.facebook"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://facebook.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('IdeaForm.Instagram')"
                    label-for="twitter"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Twitter"
                      rules="url"
                    >
                      <b-form-input
                        id="twitter"
                        v-model="ideaProfile.twitter"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://Instagram.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('IdeaForm.LinkedIn')"
                    label-for="linked-in"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="LinkedIn"
                      rules="url"
                    >
                      <b-form-input
                        id="linked-in"
                        v-model="ideaProfile.linkedIn"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://linkedin.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    :label="$t('IdeaForm.Website')"
                    label-for="Other"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Other"
                      rules="url"
                    >
                      <b-form-input
                        id="Other"
                        v-model="ideaProfile.otherURLS"
                        :state="errors.length > 0 ? false : null"
                        placeholder="https://other.com/abc"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-icon"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-icon">{{
                        $t("IdeaForm.main_image")
                      }}</label>
                      <b-form-file
                        v-model="icon"
                        :state="errors.length > 0 ? false : null"
                        accept="image/jpeg, image/png, image/gif"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-images"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-images">{{
                        $t("IdeaForm.idea_images")
                      }}</label>
                      <b-form-file
                        v-model="images"
                        accept="image/jpeg, image/png, image/gif"
                        :state="errors.length > 0 ? false : null"
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                        :multiple="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="idea-documents"
                      :rules="`size:${$sizeFilesProfile}`"
                    >
                      <label class="" for="idea-documents">{{
                        $t("IdeaForm.idea_documents")
                      }}</label>
                      <b-form-file
                        v-model="docouments"
                        accept="application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        :state="errors.length > 0 ? false : null"
                        multiple
                        :placeholder="$t('common.Choose_file_drop')"
                        :browse-text="$t('common.Browse')"
                        drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group>
                    <validation-provider #default="{ errors }" name="">
                      <b-form-checkbox
                        id="isSold"
                        v-model="ideaProfile.isSold"
                        name="isSold"
                      >
                        {{ $t("IdeaForm.isSold") }}
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- submit button -->
                <b-col cols="12">
                  <b-alert
                    v-if="errorMessage"
                    show
                    variant="warning"
                    class="warning"
                    style="color: red"
                    >{{ errorMessage }}</b-alert
                  >
                  <br />
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                    class="mb-2"
                  >
                    {{ $t("common.Edit") }}
                  </b-button>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="FileIcon" />
                <span>{{ $t("dashboard.files") }}</span>
              </template>
              <br />
              <b-row>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    :items="ideaProfile.files"
                    :fields="
                      $store.state.locale.locale == 'ar' ? fieldsArabic : fields
                    "
                  >
                    <template #cell(assets)="data">
                      <div v-viewer class="images">
                        <b-avatar
                          v-if="data.item.type.includes('image')"
                          variant="primary"
                          :src="data.item.fileUrl"
                          text="BV"
                        />
                      </div>
                      <a
                        v-if="data.item.type.includes('application')"
                        :href="data.item.fileUrl"
                        target="_blank"
                        >{{ $t("dashboard.Preview") }}</a
                      >
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        small
                        variant="danger"
                        @click="deleteFileById(data.item._id)"
                      >
                        {{ $t("dashboard.Delete") }}
                      </b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </b-card>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
// import VuePhoneNumberInput from 'vue-phone-number-input'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import "../../../../layouts/landpage/ValidationFrom.js";

// eslint-disable-next-line import/no-extraneous-dependencies
import "viewerjs/dist/viewer.css";
import {
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BAvatar,
  BCol,
  BButton,
  BFormFile,
  BTable,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BFormInvalidFeedback,
  BAlert,
  BCardTitle,
  BOverlay,
  BFormCheckbox,
  BTabs,
  BTab,
} from "bootstrap-vue";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BAvatar,
    BButton,
    VueViewer,
    BFormFile,
    BCard,
    vSelect,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    BFormDatepicker,
    BFormTextarea,
    BFormInvalidFeedback,
    BCardTitle,
    Treeselect,
    // VuePhoneNumberInput,
    BAlert,
    BOverlay,
    BFormCheckbox,
    BTabs,
    BTab,
  },
  data() {
    return {
      show: false,
      icon: null,
      images: [],
      docouments: [],
      boxTwo: "",
      fields: [
        {
          key: "name",
          sortable: true,
        },
        {
          key: "assets",
          sortable: false,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      fieldsArabic: [
        {
          label: "اسم الملف",
          key: "name",
          sortable: true,
        },
        {
          label: "الملف",
          key: "assets",
          sortable: false,
        },
        {
          label: "الإجراء",
          key: "actions",
          sortable: false,
        },
      ],
      ideaProfile: {
        address: {
          profileCity: "",
          profileState: "",
          profileCountry: {
            _id: "",
            name: "",
            id: "",
            value: "",
            text: "",
          },
        },
        socialMedia: {
          facebook: "",
          twitter: "",
          linkedIn: "",
          otherURLS: "",
        },
        idea: {
          ideaStatus: "",
          ideaReference: "",
        },
        _id: "",
        name: " ",
        typeofOwner: "",
        detailsofOwner: "",
        highlights: "",
        description: "",
        files: [],
        profileIndustries: [],
        status: "",
        blockchainExchange: "",
        profileType: "",
        category: {
          _id: "",
          name: "",
          arabicName: "",
          id: "",
          value: "",
          text: "",
        },
        saleType: "",

        userId: {
          _id: "",
          username: "",
        },
        auctionId: null,
        comments: [],
        totalComments: 0,
        totalReactions: 0,
        reactings: [],
        createdAt: "",
        updatedAt: "",
        isSold: false,
      },

      errorMessage: "",
      // isValidNumber: null,
      createIdeaProfile: {
        profileIndustries: null,
        oldTypeOfOwner: null,
        selectedState: null,
        name: "",
        selectedOwnerType: "",
        OwnerDetails: "",
        highlights: "",
        description: "",
        selectedCountry: null,
        selectedIndustry: "",
        city: "",
        twitterUrl: "",
        facebookUrl: "",
        OtherUrl: "",
        linkedinUrl: "",
        selectedExchange: "",
        selectedCategory: "",
        selectedSaleType: "",
        SelectedCurrency: "",
        Price: "",
        selectedStatus: "",
        Reference: "",
        icon: null,
        images: [],
        docouments: [],
        industries: [],
        // phoneNumber: '',
        // phoneCode: '',
        // email: '',
        OwnerTypeOption: [
          { value: "individual", text: "Individual" },
          { value: "group", text: "Group" },
        ],
        OwnerTypeOptionArabic: [
          { value: "individual", text: "فرد" },
          { value: "group", text: "مجموعة" },
        ],
        ExchangeOption: [
          { value: "true", text: "Yes" },
          { value: "false", text: "No" },
        ],
        ExchangeOptionArabic: [
          { value: "true", text: "نعم" },
          { value: "false", text: "لا" },
        ],
        selectedSaleTypeOption: [],
        SaleTypeOption: [
          { value: "1", text: "My Own Price" },
          { value: "2", text: "Auction" },
        ],
        SaleTypeOptionArabic: [
          { value: "1", text: "سعري الخاص" },
          { value: "2", text: "مزاد" },
        ],

        StatusOption: [
          { value: "1", text: "Complete" },
          { value: "2", text: "Incomplete" },
        ],
        StatusOptionArabic: [
          { value: "1", text: "مكتملة" },
          { value: "2", text: "غير مكتملة" },
        ],
        status: [],
        oldExchangeValue: null,
        selectedBlockChainObtion: [],
      },
    };
  },
  methods: {
    pluck(array, key) {
      return array.map((o) => o[key]);
    },
    ...mapActions({
      fetchCategories: "profile/retreiveCategories",
      fetchIndustries: "profile/retreiveIndustries",
      editIdea: "profile/editIdea",
      retreiveIdeaProfile: "profile/retreiveIdeaProfile",
      fetchCurrencies: "currency/retreiveCurrencies",
      fetchCountries: "country/retreiveCountries",
      fetchStates: "country/retreiveStates",
      deleteFile: "profile/deleteFile",
    }),
    resetDataSaleType() {
      // this.createIdeaProfile.selectedSaleTypeOption = "";
      this.ideaProfile.currencyId = "";
      this.ideaProfile.priceNumber = "";
    },

    normalizerArabic(node) {
      return {
        id: node.id,
        label: node.arabicName != null ? node.arabicName : node.label,
        children: node.children,
      };
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    handeTreeSelect(data) {
      if (data.length > 6) {
        this.ideaProfile.profileIndustries.length = 6;
      }
    },
    updatePage() {
      this.retreiveIdeaProfile(this.$route.params.id)
        .then((response) => {
          if (
            response.data.data.userId._id == this.$store.state.auth.user._id
          ) {
            this.ideaProfile = response.data.data;

            if (this.ideaProfile.socialMedia) {
              this.ideaProfile.facebook = this.ideaProfile.socialMedia.facebook;
              this.ideaProfile.linkedIn = this.ideaProfile.socialMedia.linkedIn;
              this.ideaProfile.twitter = this.ideaProfile.socialMedia.twitter;
              this.ideaProfile.otherLinks =
                this.ideaProfile.socialMedia.otherLinks;
            }

            this.ideaProfile.typeofOwner = {
              value:
                this.ideaProfile.typeofOwner == "individual"
                  ? "individual"
                  : "group",
              text:
                this.ideaProfile.typeofOwner == "individual"
                  ? this.$store.state.locale.locale == "ar"
                    ? "فرد"
                    : "Individual"
                  : this.$store.state.locale.locale == "ar"
                  ? "مجموعة"
                  : "Group",
            };

            this.ideaProfile.category = {
              value: this.ideaProfile.category.value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.ideaProfile.category.arabicName
                  : this.ideaProfile.category.text,
            };

            this.ideaProfile.address.profileCountry = {
              value: this.ideaProfile.address.profileCountry.value,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.ideaProfile.address.profileCountry.arabicName
                  : this.ideaProfile.address.profileCountry.text,
            };

            this.ideaProfile.address.profileState = JSON.parse(
              this.ideaProfile.address.profileState
            );
            this.ideaProfile.address.profileState = {
              value: this.ideaProfile.address.profileState.id,
              text:
                this.$store.state.locale.locale == "ar"
                  ? this.ideaProfile.address.profileState.arabicName !=
                    undefined
                    ? this.ideaProfile.address.profileState.arabicName
                    : this.ideaProfile.address.profileState.name
                  : this.ideaProfile.address.profileState.name,
            };

            this.ideaProfile.profileIndustries = this.pluck(
              this.ideaProfile.profileIndustries,
              "id"
            );

            // this.oldTypeOfOwner = response.data.data.typeofOwner;
            // this.profileIndustries = response.data.data.profileIndustries;
            // this.profileIndustries = this.pluck(this.profileIndustries, "id");
            this.oldExchangeValue = response.data.data.blockchainExchange;
            switch (response.data.data.blockchainExchange) {
              case false:
                this.createIdeaProfile.selectedBlockChainObtion.push({
                  value: "false",
                  text: this.$store.state.locale.locale == "ar" ? "لا" : "NO",
                });
                break;
              case true:
                this.createIdeaProfile.selectedBlockChainObtion.push({
                  value: "true",
                  text: this.$store.state.locale.locale == "ar" ? "نعم" : "Yes",
                });
                break;
              default:
                this.createIdeaProfile.selectedBlockChainObtion.push({
                  value: "false",
                  text: this.$store.state.locale.locale == "ar" ? "لا" : "NO",
                });
            }

            this.createIdeaProfile.selectedBlockChainObtion =
              this.createIdeaProfile.selectedBlockChainObtion[0];

            const cond = response.data.data.saleType;
            if (cond != undefined) {
              switch (cond) {
                case "1":
                  this.createIdeaProfile.selectedSaleTypeOption.push({
                    text:
                      this.$store.state.locale.locale == "ar"
                        ? "سعري الخاص"
                        : "My Own Price",
                    value: "1",
                  });
                  break;
                case "2":
                  this.createIdeaProfile.selectedSaleTypeOption.push({
                    text:
                      this.$store.state.locale.locale == "ar"
                        ? "مزاد"
                        : "Auction",
                    value: "2",
                  });
                  break;
                default:
                  this.createIdeaProfile.selectedSaleTypeOption.push({
                    text:
                      this.$store.state.locale.locale == "ar"
                        ? "سعري الخاص"
                        : "My Own Price",
                    value: "1",
                  });
              }
            }

            this.createIdeaProfile.selectedSaleTypeOption =
              this.createIdeaProfile.selectedSaleTypeOption[0];
            switch (response.data.data.idea.ideaStatus) {
              case "1":
                this.createIdeaProfile.status.push({
                  text:
                    this.$store.state.locale.locale == "ar"
                      ? "مكتملة"
                      : "Complete",
                  value: "1",
                });
                break;
              case "2":
                this.createIdeaProfile.status.push({
                  text:
                    this.$store.state.locale.locale == "ar"
                      ? "غير مكتملة"
                      : "Incomplete",
                  value: "2",
                });
                break;
              default:
                this.createIdeaProfile.status.push({
                  text:
                    this.$store.state.locale.locale == "ar"
                      ? "مكتملة"
                      : "Complete",
                  value: "1",
                });
            }
            this.createIdeaProfile.status = this.createIdeaProfile.status[0];

            this.ideaProfile.priceNumber = this.ideaProfile.price.priceNumber;

            this.ideaProfile.currencyId = {
              value: this.ideaProfile.price.currencyId._id,
              text: this.ideaProfile.price.currencyId.text,
            };
          } else {
            window.location.href = "/error-404";
          }
        })
        .catch(() => {});
    },
    deleteFileById(id) {
      const deleteData = new FormData();
      deleteData.append("fileId", id);
      deleteData.append("profileId", this.ideaProfile._id);
      this.boxTwo = "";
      this.$bvModal
        .msgBoxConfirm(this.$t("messages.Please_confirm_delete"), {
          title: this.$t("messages.Please_Confirm"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("messages.YES"),
          cancelTitle: this.$t("messages.NO"),
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.deleteFile(deleteData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: this.$t("messages.Deleted_successfully"),
                    icon: "CoffeeIcon",
                    variant: "success",
                  },
                });
                this.updatePage();
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: error.response.data.error.message,
                    icon: "CoffeeIcon",
                    variant: "error",
                  },
                });
                this.$router.push({ name: "idea-listAll" });
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    // onUpdate(payload) {
    //   // console.log(payload);
    //   this.isValidNumber = payload.isValid
    //   this.createIdeaProfile.phoneCode = payload.countryCode
    // },
    retreiveStates(data) {
      this.fetchStates(data);
    },
    removeThisFile(thisFile) {
      this.$refs.MyDropzone.removeFile(thisFile);
      console.log("File removed!");
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.show = true;
          const profileData = new FormData();
          profileData.append("name", this.ideaProfile.name);
          // if (
          //   this.ideaProfile.typeofOwner.value &&
          //   this.ideaProfile.typeofOwner.value !== this.oldTypeOfOwner
          // ) {
          //   profileData.append(
          //     "typeofOwner",
          //     this.ideaProfile.typeofOwner.value
          //   );
          // } else {
          profileData.append("typeofOwner", this.ideaProfile.typeofOwner.value);

          profileData.append(
            "profileCountry",
            this.ideaProfile.address.profileCountry.value
          );
          profileData.append(
            "profileCity",
            this.ideaProfile.address.profileCity
          );
          profileData.append(
            "profileState",
            this.ideaProfile.address.profileState.value
          );
          profileData.append(
            "profileIndustries",
            JSON.stringify(this.ideaProfile.profileIndustries)
          );
          if (this.ideaProfile.facebook) {
            profileData.append("facebook", this.ideaProfile.facebook);
          }
          if (this.ideaProfile.twitter) {
            profileData.append("twitter", this.ideaProfile.twitter);
          }
          if (this.ideaProfile.linkedIn) {
            profileData.append("linkedIn", this.ideaProfile.linkedIn);
          }
          if (this.ideaProfile.otherURLS) {
            profileData.append("otherURLS", this.ideaProfile.otherURLS);
          }

          profileData.append(
            "blockchainExchange",
            this.createIdeaProfile.selectedBlockChainObtion.value
          );

          profileData.append("category", this.ideaProfile.category.value);

          if (
            this.createIdeaProfile.selectedSaleTypeOption &&
            this.ideaProfile.category.value == "62a584a04c2a541d1939a10c"
          ) {
            profileData.append(
              "saleType",
              this.createIdeaProfile.selectedSaleTypeOption.value
            );

            if (this.ideaProfile.currencyId) {
              profileData.append(
                "currencyId",
                this.ideaProfile.currencyId.value
              );
            }

            if (this.ideaProfile.priceNumber) {
              profileData.append("priceNumber", this.ideaProfile.priceNumber);
            }
          }

          profileData.append("ideaStatus", this.createIdeaProfile.status.value);
          profileData.append(
            "ideaReference",
            this.ideaProfile.idea.ideaReference
          );
          profileData.append("description", this.ideaProfile.description);
          profileData.append("detailsofOwner", this.ideaProfile.detailsofOwner);
          profileData.append("highlights", this.ideaProfile.highlights);

          profileData.append("isSold", this.ideaProfile.isSold);

          profileData.append("profileType", "idea");
          profileData.append("profileId", this.ideaProfile._id);
          if (this.icon) {
            profileData.append("profileIcon", this.icon);
          }
          if (this.images) {
            // eslint-disable-next-line no-restricted-syntax
            for (const i of Object.keys(this.images)) {
              profileData.append("profileImages", this.images[i]);
            }
          }
          if (this.docouments) {
            // eslint-disable-next-line no-restricted-syntax
            for (const i of Object.keys(this.docouments)) {
              profileData.append("profileDocuments", this.docouments[i]);
            }
          }
          // eslint-disable-next-line no-underscore-dangle
          profileData.append("userId", this.user._id);
          this.editIdea(profileData).then((response) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$t("messages.Modified_successfully"),
                icon: "CoffeeIcon",
                variant: "success",
              },
            });
            this.show = false;
            this.$router.push({ name: "ideas-listAll" });
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters({
      categories: "profile/getIdeaCategories",
      industries: "profile/getAllIndustries",
      currencies: "currency/getCurrencies",
      countries: "country/getCountries",
      statesOptions: "country/getAllStates",
      user: "auth/user",
    }),
  },
  created() {
    this.updatePage();
  },
  mounted() {
    this.fetchCategories();
    this.fetchIndustries();
    this.fetchCurrencies();
    this.fetchCountries();
    this.configValidate();
  },
};
</script>

<style type="text/css">
@charset "UTF-8";

[dir] label {
  font-size: 14px;
}
[dir] .form-control {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  font-size: 15px;
}

[dir] .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn {
  background-color: #366ce3;
  border-color: #2d5dc7;
}
[dir] .vs__selected {
  background-color: #366ce3;
}
.col-md-6 {
  margin-bottom: 5px;
}

.required:after {
  content: " *";
  color: red;
}
[dir] .dropzone.dz-clickable {
  cursor: pointer;
  min-height: 150px;
  max-height: 260px;
  overflow-y: scroll;
}
</style>
