var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-form',[(_vm.ideaProfile)?_c('b-card',[_c('b-card-title',[_c('h2',[_vm._v(_vm._s(_vm.$t("IdeaForm.Edit_idea")))])]),_c('hr'),_c('b-tabs',[_c('b-tab',{attrs:{"active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"Edit3Icon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.content")))])]},proxy:true}],null,false,760609316)},[_c('br'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("IdeaForm.Idea_name"))+" ")]),_c('validation-provider',{attrs:{"rules":"required","name":"Idea name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.Idea_name')},model:{value:(_vm.ideaProfile.name),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "name", $$v)},expression:"ideaProfile.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,213382281)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Idea belongs to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"","label-for":"","state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"owner_type"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.Idea_belongs_to"))+" ")]),_c('v-select',{attrs:{"id":"State","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createIdeaProfile.OwnerTypeOptionArabic
                          : _vm.createIdeaProfile.OwnerTypeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.ideaProfile.typeofOwner),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "typeofOwner", $$v)},expression:"ideaProfile.typeofOwner"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3525088621)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Country"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.country_reside")))]),_c('v-select',{attrs:{"id":"Country","options":_vm.countries,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.retreiveStates(
                          _vm.ideaProfile.address.profileCountry.value
                        )}},model:{value:(_vm.ideaProfile.address.profileCountry),callback:function ($$v) {_vm.$set(_vm.ideaProfile.address, "profileCountry", $$v)},expression:"ideaProfile.address.profileCountry"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,550266942)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"State"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.state_reside")))]),_c('v-select',{attrs:{"id":"State","options":_vm.statesOptions,"label":"text"},model:{value:(_vm.ideaProfile.address.profileState),callback:function ($$v) {_vm.$set(_vm.ideaProfile.address, "profileState", $$v)},expression:"ideaProfile.address.profileState"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,1633761726)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"City"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.city_reside")))]),_c('b-form-input',{attrs:{"id":"City","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.city_reside')},model:{value:(_vm.ideaProfile.address.profileCity),callback:function ($$v) {_vm.$set(_vm.ideaProfile.address, "profileCity", $$v)},expression:"ideaProfile.address.profileCity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1611274486)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Industry","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Industry"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.industries_belong"))+" ")]),_c('div',{attrs:{"id":"app"}},[_c('treeselect',{attrs:{"multiple":true,"placeholder":_vm.$t('common.Select'),"options":_vm.industries,"normalizer":_vm.$store.state.locale.locale == 'ar'
                            ? _vm.normalizerArabic
                            : _vm.normalizer},on:{"input":_vm.handeTreeSelect},model:{value:(_vm.ideaProfile.profileIndustries),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "profileIndustries", $$v)},expression:"ideaProfile.profileIndustries"}})],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,927520225)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"highlights","rules":("required|max:" + _vm.$maxHighlightsProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"highlights"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.Brief_overview")))]),_c('b-form-textarea',{attrs:{"id":"highlights","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.Brief_overview'),"rows":"2","max-rows":"2","no-auto-shrink":""},model:{value:(_vm.ideaProfile.highlights),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "highlights", $$v)},expression:"ideaProfile.highlights"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4203690457)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Reference","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"Reference"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.Include_references"))+" ")]),_c('b-form-textarea',{attrs:{"id":"Reference","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.Include_references_p'),"rows":"2","max-rows":"2","no-auto-shrink":""},model:{value:(_vm.ideaProfile.idea.ideaReference),callback:function ($$v) {_vm.$set(_vm.ideaProfile.idea, "ideaReference", $$v)},expression:"ideaProfile.idea.ideaReference"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1168477723)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Owner Details","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"Owner-Details"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.description_owner"))+" ")]),_c('b-form-textarea',{attrs:{"id":"Owner-Details","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.description_owner'),"rows":"3","max-rows":"3","no-auto-shrink":""},model:{value:(_vm.ideaProfile.detailsofOwner),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "detailsofOwner", $$v)},expression:"ideaProfile.detailsofOwner"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2331787419)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('label',{staticClass:"required",attrs:{"for":"description"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.description_idea"))+" ")]),_c('b-form-textarea',{attrs:{"id":"description","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.description_idea'),"rows":"3","max-rows":"3","no-auto-shrink":""},model:{value:(_vm.ideaProfile.description),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "description", $$v)},expression:"ideaProfile.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2795362295)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Category"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.needs_from_sharing")))]),_c('v-select',{attrs:{"id":"Category","options":_vm.categories,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},on:{"input":function($event){return _vm.resetDataSaleType()}},model:{value:(_vm.ideaProfile.category),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "category", $$v)},expression:"ideaProfile.category"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2579373967)})],1),(
                  _vm.ideaProfile.category.value == '62a584a04c2a541d1939a10c'
                )?_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"sale type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"saleType"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.How_sell_idea")))]),_c('v-select',{attrs:{"id":"saleType","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createIdeaProfile.SaleTypeOptionArabic
                          : _vm.createIdeaProfile.SaleTypeOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createIdeaProfile.selectedSaleTypeOption),callback:function ($$v) {_vm.$set(_vm.createIdeaProfile, "selectedSaleTypeOption", $$v)},expression:"createIdeaProfile.selectedSaleTypeOption"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,2593617672)})],1):_vm._e(),(
                  _vm.createIdeaProfile.selectedSaleTypeOption != undefined &&
                  _vm.createIdeaProfile.selectedSaleTypeOption.value == '1' &&
                  _vm.ideaProfile.category.value == '62a584a04c2a541d1939a10c'
                )?_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"Currency","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Currency"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.Currency")))]),_c('v-select',{attrs:{"id":"Currency","options":_vm.currencies,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.ideaProfile.currencyId),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "currencyId", $$v)},expression:"ideaProfile.currencyId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,878438472)})],1),_c('b-col',{attrs:{"md":"8"}},[_c('validation-provider',{attrs:{"name":"Price","rules":"required|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Price"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.Price")))]),_c('b-form-input',{attrs:{"id":"Price","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('IdeaForm.My_Own_Price')},model:{value:(_vm.ideaProfile.priceNumber),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "priceNumber", $$v)},expression:"ideaProfile.priceNumber"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,3637634748)})],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"Status"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.Status")))]),_c('v-select',{attrs:{"id":"Status","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createIdeaProfile.StatusOptionArabic
                          : _vm.createIdeaProfile.StatusOption,"selectable":function (option) { return !option.value.includes('select_value'); },"label":"text"},model:{value:(_vm.createIdeaProfile.status),callback:function ($$v) {_vm.$set(_vm.createIdeaProfile, "status", $$v)},expression:"createIdeaProfile.status"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,823143886)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"blockchain exchange","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false : null}},[_c('label',{staticClass:"required",attrs:{"for":"blockchain_exchange"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.blockchain_exchange")))]),_c('v-select',{attrs:{"id":"blockchain_exchange","options":_vm.$store.state.locale.locale == 'ar'
                          ? _vm.createIdeaProfile.ExchangeOptionArabic
                          : _vm.createIdeaProfile.ExchangeOption,"selectable":function (option) { return !option.value.includes('nothing_selected'); },"label":"text"},model:{value:(_vm.createIdeaProfile.selectedBlockChainObtion),callback:function ($$v) {_vm.$set(_vm.createIdeaProfile, "selectedBlockChainObtion", $$v)},expression:"createIdeaProfile.selectedBlockChainObtion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,false,690486021)})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IdeaForm.Facebook'),"label-for":"facebook"}},[_c('validation-provider',{attrs:{"name":"Facebook","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facebook","state":errors.length > 0 ? false : null,"placeholder":"https://facebook.com/abc"},model:{value:(_vm.ideaProfile.facebook),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "facebook", $$v)},expression:"ideaProfile.facebook"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1035388861)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IdeaForm.Instagram'),"label-for":"twitter"}},[_c('validation-provider',{attrs:{"name":"Twitter","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"twitter","state":errors.length > 0 ? false : null,"placeholder":"https://Instagram.com/abc"},model:{value:(_vm.ideaProfile.twitter),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "twitter", $$v)},expression:"ideaProfile.twitter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1828901229)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IdeaForm.LinkedIn'),"label-for":"linked-in"}},[_c('validation-provider',{attrs:{"name":"LinkedIn","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"linked-in","state":errors.length > 0 ? false : null,"placeholder":"https://linkedin.com/abc"},model:{value:(_vm.ideaProfile.linkedIn),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "linkedIn", $$v)},expression:"ideaProfile.linkedIn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3983176414)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('IdeaForm.Website'),"label-for":"Other"}},[_c('validation-provider',{attrs:{"name":"Other","rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"Other","state":errors.length > 0 ? false : null,"placeholder":"https://other.com/abc"},model:{value:(_vm.ideaProfile.otherURLS),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "otherURLS", $$v)},expression:"ideaProfile.otherURLS"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2133341065)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"idea-icon","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{attrs:{"for":"idea-icon"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.main_image")))]),_c('b-form-file',{attrs:{"state":errors.length > 0 ? false : null,"accept":"image/jpeg, image/png, image/gif","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.icon),callback:function ($$v) {_vm.icon=$$v},expression:"icon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3670929158)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"idea-images","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{attrs:{"for":"idea-images"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.idea_images")))]),_c('b-form-file',{attrs:{"accept":"image/jpeg, image/png, image/gif","state":errors.length > 0 ? false : null,"placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here...","multiple":true},model:{value:(_vm.images),callback:function ($$v) {_vm.images=$$v},expression:"images"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3554461511)})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"idea-documents","rules":("size:" + _vm.$sizeFilesProfile)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('label',{attrs:{"for":"idea-documents"}},[_vm._v(_vm._s(_vm.$t("IdeaForm.idea_documents")))]),_c('b-form-file',{attrs:{"accept":"application/pdf, application/vnd.ms-excel,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document","state":errors.length > 0 ? false : null,"multiple":"","placeholder":_vm.$t('common.Choose_file_drop'),"browse-text":_vm.$t('common.Browse'),"drop-placeholder":"Drop file here..."},model:{value:(_vm.docouments),callback:function ($$v) {_vm.docouments=$$v},expression:"docouments"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2105528401)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"isSold","name":"isSold"},model:{value:(_vm.ideaProfile.isSold),callback:function ($$v) {_vm.$set(_vm.ideaProfile, "isSold", $$v)},expression:"ideaProfile.isSold"}},[_vm._v(" "+_vm._s(_vm.$t("IdeaForm.isSold"))+" ")]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,880987199)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.errorMessage)?_c('b-alert',{staticClass:"warning",staticStyle:{"color":"red"},attrs:{"show":"","variant":"warning"}},[_vm._v(_vm._s(_vm.errorMessage))]):_vm._e(),_c('br'),_c('b-button',{staticClass:"mb-2",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("common.Edit"))+" ")])],1)],1)],1),_c('b-tab',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('feather-icon',{attrs:{"icon":"FileIcon"}}),_c('span',[_vm._v(_vm._s(_vm.$t("dashboard.files")))])]},proxy:true}],null,false,1795821457)},[_c('br'),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.ideaProfile.files,"fields":_vm.$store.state.locale.locale == 'ar' ? _vm.fieldsArabic : _vm.fields},scopedSlots:_vm._u([{key:"cell(assets)",fn:function(data){return [_c('div',{directives:[{name:"viewer",rawName:"v-viewer"}],staticClass:"images"},[(data.item.type.includes('image'))?_c('b-avatar',{attrs:{"variant":"primary","src":data.item.fileUrl,"text":"BV"}}):_vm._e()],1),(data.item.type.includes('application'))?_c('a',{attrs:{"href":data.item.fileUrl,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("dashboard.Preview")))]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"small":"","variant":"danger"},on:{"click":function($event){return _vm.deleteFileById(data.item._id)}}},[_vm._v(" "+_vm._s(_vm.$t("dashboard.Delete"))+" ")])]}}],null,false,1195272915)})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }